import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../enums/device-types";
import { EViewType } from "../../../enums/view-type";
import { IPowerConsumerWithConsumptionView } from "../../../interfaces/power-consumer-with-consumption-view";
import MaterialIcon from "../../../ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import findIcon from "../../../utils/findIcon";
import Helper from "../../../utils/helpers";
import shortenedPath from "../../../utils/shortenedPath";
import styles from "./high-power-devices.module.scss";

interface IProps {
  consumers: IPowerConsumerWithConsumptionView[] | null;
  title: string;
  isFetching?: boolean;
  viewTypes: { name: string; type: EViewType }[];
  currentViewType: number;
  setCurrentViewType: React.Dispatch<React.SetStateAction<number>>;
}

const HighPowerDevices = (props: IProps) => {
  const {
    consumers,
    title,
    isFetching,
    viewTypes,
    currentViewType,
    setCurrentViewType,
  } = props;
  const [data, setData] = useState<
    {
      name: string;
      id: string;
      units: number;
      averageCost: number;
      type: EDeviceTypes;
      percentage: number;
      path: string;
    }[]
  >([]);

  useEffect(() => {
    if (consumers && consumers.length > 0) {
      const sorted = consumers.slice().sort((a, b) => b.units - a.units);
      const max = sorted[0].units;
      const _data = sorted
        .filter((c) => Number(Helper.roundTo2(c.units)) > 0)
        .map((c) => {
          return {
            name: c.name,
            id: c.id,
            units: Number(Helper.roundTo2(c.units)),
            averageCost: c.averageCost,
            type: c.type,
            percentage: (c.units / max) * 100,
            path: c.path,
          };
        });

      setData(_data);
    } else {
      setData([]);
    }
  }, [consumers]);

  return (
    <div className={`${styles.container} container-white h-100`}>
      {isFetching ? (
        <SpinnerModal show={isFetching} withOverlay={false} size="md" />
      ) : (
        <>
          <Row className="align-items-center">
            <Col className="text-light font-size-12 font-weight-500">
              {title}
            </Col>
          </Row>
          <Row className="align-items-center mt-4">
            <Col className="col-auto">
              <MaterialIcon
                icon="chevron_left"
                className={`${
                  currentViewType > 0
                    ? "cursor-pointer"
                    : `cursor-disabled ${styles.opacity}`
                }`}
                onClick={() => {
                  setCurrentViewType((ps) => (ps - 1 < 0 ? 0 : ps - 1));
                }}
              />
            </Col>
            <Col>
              <Row className="align-items-center">
                {consumers === null || data.length < 1 ? (
                  <Col className={`font-size-12 text-light text-center`}>
                    No energy consumption has been recorded yet.
                  </Col>
                ) : (
                  <>
                    {data.map((c, index) => (
                      <Col
                        key={index}
                        xs={12}
                        lg={6}
                        className={styles.contentRowLeft}
                      >
                        {c.units > 0 && <Device {...c} />}
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Col>
            <Col className="col-auto">
              <MaterialIcon
                icon="chevron_right"
                className={`${
                  currentViewType < viewTypes.length - 1
                    ? "cursor-pointer"
                    : `cursor-disabled ${styles.opacity}`
                }`}
                onClick={() => {
                  setCurrentViewType((ps) =>
                    ps + 1 > viewTypes.length - 1
                      ? viewTypes.length - 1
                      : ps + 1
                  );
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

interface IDeviceProps {
  name: string;
  id: string;
  units: number;
  averageCost: number;
  type: EDeviceTypes;
  percentage: number;
  path: string;
}

const Device = (props: IDeviceProps) => {
  const { name, units, type, percentage, path, averageCost } = props;
  return (
    <Row className={`${styles.deviceContainer} align-items-center`}>
      <Col className={`${styles.left} ps-0`}>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <div className={`${type} ${styles.icon}`}>
              <MaterialIcon size={16} icon={findIcon(type)} />
            </div>
          </Col>
          <Col className="ps-2 pe-0">
            <Tooltip
              placement="top"
              trigger={["hover"]}
              overlay={
                <div>
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      {name}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-12">
                      {path.length > 80 ? shortenedPath(path) : path}
                    </Col>
                  </Row>
                </div>
              }
            >
              <label className="text-truncate" style={{ maxWidth: "70px" }}>
                {name}
              </label>
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col className={`${styles.right} text-end p-0 pe-0`}>
        <Row className="align-items-center">
          <Col className="pe-0">
            <div className={styles.progress}>
              <div className={type} style={{ width: `${percentage}%` }}></div>
            </div>
          </Col>
          <Col className={`${styles.units} ps-2 col-4 text-start`}>
            <Row>
              <Col>
                <label>{`${units}`}&nbsp;Units</label>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${Helper.roundTo2(averageCost)}`}&nbsp;LKR</label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default HighPowerDevices;
