import { Col, Row } from "react-bootstrap";
import { EConsumptionValueComparison } from "../../../enums/consumption-comparison";
import { EUsageDataFrom } from "../../../enums/usage-data-from";
import { IEnergyView } from "../../../interfaces/entities/energy-view";
import UsageValue from "../../../ui-elements/usage-value/usage-value";
interface IProps {
  title: string;
  subTitle1?: string;
  subTitle2?: string;
  subTitle3?: string;
  value1?: IEnergyView;
  value2?: IEnergyView;
  value3?: IEnergyView;
  consumptionValueComparison?: EConsumptionValueComparison;
  isFetching: boolean;
  usageDataFrom?: EUsageDataFrom;
}

const UsageCard = (props: IProps) => {
  const {
    title,
    subTitle1,
    subTitle2,
    subTitle3,
    value1,
    value2,
    value3,
    consumptionValueComparison,
    usageDataFrom,
    isFetching,
  } = props;

  return (
    <div className="container-white">
      <Row>
        <Col className="text-light font-size-12 font-weight-500">{title}</Col>
      </Row>
      <Row className="mt-1 align-items-start">
        {subTitle1 &&
          (value1 ? (
            <Col sm={4} xl={5}>
              <UsageValue
                cost={value1.energyBill}
                units={value1.energyInUnits}
                subTitle={subTitle1 || ""}
                isFetching={isFetching}
                comparisonWithLastMonth={consumptionValueComparison}
                usageDataFrom={usageDataFrom}
              />
            </Col>
          ) : (
            <Col sm={4} xl={5}>
              <div className="py-2 px-2">
                <Row>
                  <Col className="text-light font-size-10">{subTitle1}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-600 text-dark">Error</Col>
                </Row>
              </div>
            </Col>
          ))}
        {subTitle2 &&
          (value2 ? (
            <Col xs={12} className="col-sm">
              <UsageValue
                cost={value2.energyBill}
                units={value2.energyInUnits}
                subTitle={subTitle2 || ""}
                isFetching={isFetching}
              />
            </Col>
          ) : (
            <Col xs={12} className="col-sm">
              <div className="py-2 px-2">
                <Row>
                  <Col className="text-light font-size-10">{subTitle2}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-600 text-dark">Error</Col>
                </Row>
              </div>
            </Col>
          ))}
        {subTitle3 &&
          (value3 ? (
            <Col xs={12} className="col-sm">
              <UsageValue
                cost={value3.energyBill}
                units={value3.energyInUnits}
                subTitle={subTitle3}
                isFetching={isFetching}
              />
            </Col>
          ) : (
            <Col xs={12} className="col-sm">
              <div className="py-2 px-2">
                <Row>
                  <Col className="text-light font-size-10">{subTitle3}</Col>
                </Row>
                <Row>
                  <Col className="font-weight-600 text-dark">Error</Col>
                </Row>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default UsageCard;
